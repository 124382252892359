import React from "react"
import styled from "styled-components"

export default ({ html }) => {
  const isIframe = html => {
    return html.includes("iframe")
  }
  return isIframe(html) ? (
    <Wrapper dangerouslySetInnerHTML={{ __html: html }} />
  ) : (
    <Wrapper>
      <Iframe scrolling="no" allow="autoplay" src={html} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 35px 0;

  header + & {
    margin-bottom: 117px;
  }
  iframe {
    width: 100%;
    height: 300px;
    border: none;
  }
`

const Iframe = styled.iframe``
