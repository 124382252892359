import React from "react"
import { graphql, Link } from "gatsby"
import Media from "src/components/Media"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import { PrimaryTitle } from "src/components/Typography"
import useMousePosition from "src/hooks/useMousePosition"
import { get } from "lodash"

export default ({ title, items }) => {
  const { x, y } = useMousePosition()

  function showPreview(e) {
    const linkPosition = e.target.getBoundingClientRect()
    const image = e.target.nextSibling
    if (image && image.style) {
      image.style.top = parseInt(y - linkPosition.y) - 10 + "px"
      image.style.left = parseInt(x - linkPosition.x) + 40 + "px"
    }
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      <List>
        {items.map(
          ({ title, author, category, path, featured_media }, index) => {
            return (
              <Item key={index}>
                <ItemTitle
                  onMouseMove={e => {
                    showPreview(e)
                  }}
                >
                  <ItemTitleLink
                    to={path}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                  {featured_media && (
                    <ItemImagePreview media={featured_media} size="thumbnail" />
                  )}
                </ItemTitle>
                <ItemMeta>
                  <ItemAuthor>
                    <Link to={author.path}>{author.name}</Link>
                  </ItemAuthor>
                  {category && (
                    <ItemCategory>
                      <Link to={category.path}>{category.name}</Link>
                    </ItemCategory>
                  )}
                </ItemMeta>
              </Item>
            )
          }
        )}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.section``

const Title = styled(PrimaryTitle)`
  display: block;

  ${media.tablet} {
    display: none;
  }

  ${media.desktop} {
    display: block;
  }
`

const List = styled.ul`
  margin-top: 30px;

  ${media.tablet} {
    margin-top: 10px;
  }

  ${media.desktop} {
    margin-top: 30px;
  }
`

const Item = styled.li`
  &:not(:first-child) {
    padding-top: 19px;
    border-top: 1px solid #ececec;
  }

  &:not(:last-child) {
    padding-bottom: 20px;
  }
`

const ItemTitle = styled.h3`
  margin-bottom: 12px;
  position: relative;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`

const ItemTitleLink = styled(Link)``

const ItemImagePreview = styled(Media)`
  display: none !important;

  ${media.pointer} {
    display: block !important;
    visibility: hidden;
    max-width: 160px;
    max-height: 160px;
    position: absolute !important;
    top: 0;
    left: 100px;
    z-index: 1;
    border: 2px solid;
    pointer-events: none;

    ${ItemTitleLink}:hover + & {
      visibility: visible;
    }
  }
`

const ItemMeta = styled.p`
  font-size: 14px;
  line-height: 18px;

  > * {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  > :nth-child(n + 2) {
    color: rgba(0, 0, 0, 0.5);
  }
`

const ItemAuthor = styled.span`
  font-weight: 600;
`

const ItemCategory = styled.span``
