import React, { useMemo } from "react"
import { media } from "src/styles/breakpoints"
import PropTypes from "prop-types"
import { orderBy } from "lodash"
import styled, { css } from "styled-components"

export default function RankingChart({ data = [], highlightedYear }) {
  const renderedData = useMemo(() => {
    let result = orderBy(data, "year", "desc")
    // For testing >>>
    // let y = 2020
    // let result = new Array(19).fill('').map((_, i) => {
    //   return {
    //     place: random(0, 100),
    //     year: y - i
    //   }
    // })
    // <<< For testing
    const { length } = result

    // Set barHeights
    if (length) {
      const sortedPlaces = orderBy(result, "place", "asc").map(
        item => item.place
      )

      function getBarHeight({ forPlace }) {
        const thresholdsDiff = 60
        const index = sortedPlaces.indexOf(forPlace)
        return 100 - index * (thresholdsDiff / sortedPlaces.length - 1) + "%"
      }

      result = result.map(({ place, year }) => ({
        place,
        year,
        barHeight: getBarHeight({ forPlace: place }),
      }))
    }

    // Fill missing years
    if (length < 18) {
      const mostPresentYear = new Date().getFullYear()
      const mostPastYear = mostPresentYear - 18
      const mostPastYearInData = result[length - 1].year
      const numOfYearsToAdd = mostPastYearInData
        ? mostPastYearInData - mostPastYear
        : mostPresentYear - mostPastYear
      const startWithYear = mostPastYearInData - 1 || mostPresentYear

      let counter = startWithYear

      for (let i = 0; i < numOfYearsToAdd; i++) {
        result.push({
          place: undefined,
          year: counter,
        })

        counter--
      }
    }

    return result
  }, [data.length])

  return (
    <Wrapper>
      <Inner>
        <List>
          {renderedData.map(({ place, year, barHeight }, index) => {
            const bold = !highlightedYear ? !index : Number(year) === Number(highlightedYear)
            return (
              <Column key={index}>
                <Year scBold={bold}>{year}</Year>
                <Place>{place}</Place>
                <Bar
                  scVisible={bold}
                  style={{ height: `calc(${barHeight} - 60px)` }}
                />
              </Column>
            )
          })}
        </List>
      </Inner>
    </Wrapper>
  )
}

RankingChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      place: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      year: PropTypes.string,
    })
  ),
}

const Wrapper = styled.section`
  position: relative;
  margin-top: 35px;
  margin-left: -${p => p.theme.sidePadding.phone}px;
  margin-right: -${p => p.theme.sidePadding.phone}px;
  margin-bottom: 55px;
  padding-left: ${p => p.theme.sidePadding.phone}px;
  height: 230px;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    bottom: 26px;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${p => p.theme.color.mercury};
  }

  ${media.tablet} {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }
`

const Inner = styled.div`
  margin-left: -15px;
  width: calc(100% + 30px);
  height: calc(100% + 50px);
  padding-bottom: 50px;
  overflow-y: hidden;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
`

const List = styled.ul`
  display: flex;
  height: 100%;
  min-width: ${p => p.theme.containerWidth}px;
`

const Column = styled.li`
  width: ${100 / 19}%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
`

const Year = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  opacity: 0.5;
  order: 1;

  ${p =>
    p.scBold &&
    css`
      font-weight: 600;
      opacity: 1;
    `}
`

const Bar = styled.div`
  width: 2px;
  height: 100%;
  background: ${p => p.theme.color.black};
  margin: 5px 0 15px;
  visibility: ${p => !p.scVisible && "hidden"};
`

const Place = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.18;
  text-align: center;
  margin-top: auto;
`
