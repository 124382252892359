import React, { useMemo } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import parseContent from "src/utils/parseContent"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Container from "src/components/Container"
import Icon from "src/components/Icon"
import SocialMediaSharing from "src/components/SocialMediaSharing"
import SectionSlider from "src/components/SectionSlider"
import RankingChart from "src/components/RankingChart"
import Media from "src/components/Media"
import { PrimaryTitle } from "src/components/Typography"
import {
  Title,
  Subtitle,
  ArtistPosition,
  ArtistImage,
  ArtistName,
  ArtistDescription,
  ArtistChange,
} from "src/templates/pagePower100"
import {
  ArticleContent,
  TextColumn,
  SharingColumn,
  SideColumn,
} from "src/templates/article"
import routesConfig from "src/routes.config"
import locationQueryParams from "src/utils/locationQueryParams"
import { getSizes } from "src/utils/images"

export const query = graphql`
  query artistsById($wordpress_id: Int!) {
    wordpressWpArtist(wordpress_id: { eq: $wordpress_id }) {
      ...ArtistFragment
    }
  }
`

export default props => {
  const {
    data: {
      wordpressWpArtist: {
        title,
        path,
        artist_category,
        excerpt,
        acf: { artist_power_100, hero_image },
        featured_media,
        content,
      },
    },
  } = props
  const queryParams = locationQueryParams.parse()
  const newestYear =
    artist_power_100 &&
    artist_power_100.length > 0 &&
    parseInt(artist_power_100[0].edition.name)
  const requestedYear = queryParams.year || newestYear

  const requestedEdition =
    requestedYear &&
    artist_power_100.find(p => p.edition.name === String(requestedYear))
  const { place, next, prev } = requestedEdition || {}
  const isPower100 = Boolean(requestedEdition)
  const rankingChartData = (artist_power_100 || []).map(item => ({
    place: item.place,
    year: item.edition.name,
  }))
  const parsedContent = parseContent(content)

  return (
    <>
      <SEO title={title} image={featured_media} />
      <Ad type="artist/before_header" />
      <PageWrapper>
        <Header dark={true} />
        <Container>
          {isPower100 && (
            <>
              <Title>
                <Link to={routesConfig.power100()}>Power 100</Link>
              </Title>
              <Subtitle>
                Most influential people in {requestedYear} in the contemporary
                artworld
              </Subtitle>
              <ArtistProfile>
                <StyledArtistPosition as="p">{place}</StyledArtistPosition>
                {featured_media && (
                  <ArtistImage media={featured_media} type="simple" />
                )}
                <ArtistDetails>
                  <StyledArtistName
                    as="h1"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                  {artist_category && (
                    <ArtistDescription>
                      {artist_category
                        ? `${artist_category.name} - ${excerpt}`
                        : `${excerpt}`}
                    </ArtistDescription>
                  )}
                  <ArtistChange>
                    {place} in {requestedYear}
                  </ArtistChange>
                </ArtistDetails>
                <ArtistNavigation>
                  {prev && (
                    <ArtistNavigationButton
                      to={routesConfig.artistSingle(prev.path, {
                        year: requestedYear,
                      })}
                    >
                      <ArtistNavigationIcon name="arrow-left" /> Prev
                    </ArtistNavigationButton>
                  )}
                  <ArtistNavigationButton
                    to={routesConfig.power100({ year: requestedYear })}
                  >
                    <ArtistNavigationIcon name="list" /> Full list
                  </ArtistNavigationButton>
                  {next && (
                    <ArtistNavigationButton
                      to={routesConfig.artistSingle(next.path, {
                        year: requestedYear,
                      })}
                    >
                      <ArtistNavigationIcon name="arrow-right" /> Next
                    </ArtistNavigationButton>
                  )}
                </ArtistNavigation>
              </ArtistProfile>
              <RankingChart
                data={rankingChartData}
                highlightedYear={requestedYear}
              ></RankingChart>
            </>
          )}
          {hero_image && (
            <Media type="figure" media={hero_image} size="wordpress_2048x2048" sizes={getSizes({default: '1230px', xl: "100vw" })} />
          )}
          <Content>
            {!isPower100 && (
              <AuthorProfile>
                {featured_media && (
                  <AuthorProfileImage
                    type="fixed"
                    media={featured_media}
                    size="thumbnail"
                  />
                )}
                <AuthorProfileDetails>
                  <AuthorProfileName as="h1">{title}</AuthorProfileName>
                  {artist_category && (
                    <AuthorProfilePosition>
                      {artist_category.name}
                    </AuthorProfilePosition>
                  )}
                </AuthorProfileDetails>
              </AuthorProfile>
            )}
            <TextColumn>{parsedContent}</TextColumn>
            <SharingColumn>
              <SocialMediaSharing title={title} path={path} />
            </SharingColumn>
            <SideColumn>
              <Ad type="artist/sidebar" />
            </SideColumn>
          </Content>
        </Container>
        <SectionSlider source="stories" title="Related articles" />
      </PageWrapper>
      <Ad type="artist/before_footer" />
    </>
  )
}

const ArtistProfile = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 69px;
  position: relative;
  padding-left: 60px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${p => p.theme.color.mercury};

  @media (max-width: ${breakpoints.tablet - 1}px) {
    margin-left: -${p => p.theme.sidePadding.phone}px;
    margin-right: -${p => p.theme.sidePadding.phone}px;
    padding-right: ${p => p.theme.sidePadding.phone}px;
    flex-wrap: wrap;
  }

  ${media.tablet} {
    padding-left: 80px;
  }
`

const StyledArtistPosition = styled(ArtistPosition)`
  top: 0;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    left: ${p => p.theme.sidePadding.phone}px;
  }
`

const ArtistDetails = styled.div`
  flex: 1;
  margin-left: 30px;

  ${media.tablet} {
    margin-left: 90px;
    padding-top: 30px;
    text-align: center;

    &:nth-child(2) {
      margin-left: 230px;
      flex: 1;
    }
  }
`

const StyledArtistName = styled(ArtistName)`
  margin-bottom: 11px;
`

const ArtistNavigation = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: -20px;
  padding-top: 41px;

  ${media.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 220px;
    margin-left: 90px;
    padding-left: 147px;
  }
`

const ArtistNavigationButton = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    &:nth-child(2) {
      margin: 0 40px;
    }
  }
`

const ArtistNavigationIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  position: relative;

  ${ArtistNavigationButton}:nth-child(1) & {
    width: 18px;
    margin-right: 6px;
    left: -2px;
  }

  ${ArtistNavigationButton}:nth-child(3) & {
    width: 18px;
    margin-right: 6px;
  }
`

const Content = styled(ArticleContent)``

const AuthorProfile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 77px;
  padding-left: calc((100% - 70px) / 8 + 10px);
`

const AuthorProfileDetails = styled.div``

const AuthorProfileImage = styled(Media)`
  width: 140px;
  height: 140px;
  margin-right: 55px;
  border-radius: 50%;
`

const AuthorProfileName = styled(PrimaryTitle)`
  margin-bottom: 12px;
`

const AuthorProfilePosition = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
