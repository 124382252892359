import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import parseContent from "src/utils/parseContent"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Container from "src/components/Container"
import SocialMediaSharing from "src/components/SocialMediaSharing"
import Media from "src/components/Media"
import VideoPlayer from "src/components/VideoPlayer"
import SectionSlider from "src/components/SectionSlider"
import SoundCloudEmbed from "src/components/SoundCloudEmbed"
import ArticleList from "src/components/ArticleList"
import { PrimaryTitle } from "src/components/Typography"
import { articleNormalizer } from "src/utils/contentNormalizers"
import { get } from "lodash"
import { getSizes } from "src/utils/images"

export const query = graphql`
  query postByIdQuery($wordpress_id: Int) {
    wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      ...ArticleFragment
      acf {
        article_related_articles {
          ...ArticlesListFragment
        }
      }
    }
  }
`

export default props => {
  const { wordpressPost } = props.data

  const {
    title,
    excerpt,
    titleHtml,
    content,
    path,
    author,
    category,
    date,
    branch,
    format,
    imageSrc,
    videoSrc,
    acf: { hero_image, article_audio, article_related_articles },
  } = articleNormalizer(wordpressPost)

  const plainTitle = title.replace(/(<([^>]+)>)/gi, "")

  const parsedContent = parseContent(content, plainTitle, path)

  const related =
    article_related_articles && article_related_articles.map(articleNormalizer)


  return (
    <>
      <SEO title={plainTitle} description={excerpt} image={imageSrc} />
      <Ad type="article/before_header" />
      <PageWrapper>
        <Header dark={true} />
        <Container>
          <ArticleHeader>
            <Title
              dangerouslySetInnerHTML={{ __html: titleHtml || title }}
              as="h1"
            />
            <Meta>
              {author && (
                <MetaAuthor>
                  <Link to={author.path}>{author.name}</Link>
                </MetaAuthor>
              )}
              {category && (
                <MetaCategory>
                  <Link to={category.path}>{category.name}</Link>
                </MetaCategory>
              )}
              {date && <MetaDate>{date}</MetaDate>}
              {branch && <MetaBranch>{branch.name}</MetaBranch>}
            </Meta>
          </ArticleHeader>

          {format === "standard" && hero_image && (
            <Media type="figure" media={hero_image} size="wordpress_2048x2048" sizes={getSizes({default: '1230px', xl: "100vw" })} />
          )}

          {format === "video" && videoSrc && (
            <VideoPlayer
              src={videoSrc}
              autoplay={true}
              poster={imageSrc}
              standalone={true}
              title={title}
              path={path}
            />
          )}

          {format === "audio" && article_audio && (
            <SoundCloudEmbed html={article_audio} />
          )}

          <ArticleContent>
            <TextColumn>
              {parsedContent}
              <ArticleFooter>
                <ArticleFooterAuthor>
                  <Link to={author.path}>{author.name}</Link>
                </ArticleFooterAuthor>
                {category && (
                  <ArticleFooterCategory to={category.path}>
                    {category.name}
                  </ArticleFooterCategory>
                )}
                {date && <ArticleFooterDate>{date}</ArticleFooterDate>}
                {branch && (
                  <ArticleFooterBranch>{branch.name}</ArticleFooterBranch>
                )}
              </ArticleFooter>
            </TextColumn>
            <SharingColumn>
              <SocialMediaSharing title={plainTitle} path={path} />
            </SharingColumn>
            <SideColumn>
              {format === "standard" && <StyledAd type="article/sidebar" />}
              {related && (
                <ArticleList title="Related articles" items={related} />
              )}
            </SideColumn>
          </ArticleContent>
        </Container>
        <SectionSlider source="stories" title="Most recent" />
      </PageWrapper>
      <Ad type="article/before_footer" />
    </>
  )
}

const ArticleHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 66px;
  margin-bottom: 74px;
  text-align: center;

  ${media.desktop} {
    margin-bottom: 94px;
  }
`

const Title = styled(PrimaryTitle)`
  max-width: 520px;
  margin-bottom: 23px;
`

const Meta = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;

  > * {
    display: inline-block;
    margin-right: 16px;
  }

  > :nth-child(n + 2) {
    color: rgba(0, 0, 0, 0.5);
  }
`

const MetaAuthor = styled.span`
  font-weight: 600;
`

const MetaCategory = styled.span``

const MetaBranch = styled.span``

const MetaDate = styled.span``

const ArticleContent = styled.article`
  ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 9px;

    figure + & {
      margin-top: 96px;
    }
  }
`

const FullTextColumn = styled.div`
  order: 2;
  margin-bottom: 105px;
`

const TextColumn = styled.div`
  margin-bottom: 90px;

  ${media.tablet} {
    order: 2;
    width: calc(100% - 260px);
    margin-right: 60px;
    margin-bottom: 105px;
  }

  ${media.desktop} {
    width: calc(100% - 380px);
    margin-right: 80px;
  }

  ${media.desktopMedium} {
    width: calc((100% - 70px) / 8 * 4 + 30px);
    margin-left: 10px;
    margin-right: calc((100% - 70px) / 8 + 20px);
  }
`

const SharingColumn = styled.div`
  display: none;
  width: calc((100% - 70px) / 8);
  padding-top: 5px;

  ${media.desktopMedium} {
    display: block;
  }
`

const SideColumn = styled.aside`
  margin-bottom: 85px;

  ${media.tablet} {
    order: 3;
    width: 200px;
    margin-bottom: 135px;
  }

  ${media.desktop} {
    width: 300px;
  }

  ${media.desktopMedium} {
    width: calc((100% - 70px) / 8 * 2 + 10px);
  }
`

const StyledAd = styled(Ad)`
  margin-top: 4px;
  margin-bottom: 95px;
`

const ArticleFooter = styled.footer`
  margin-top: 30px;
  font-size: 14px;
  line-height: 18px;

  > * {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  > :nth-child(n + 2) {
    color: rgba(0, 0, 0, 0.5);
  }
`

const ArticleFooterAuthor = styled.span`
  font-weight: 600;
`

const ArticleFooterCategory = styled(Link)``

const ArticleFooterBranch = styled.span``

const ArticleFooterDate = styled.span``

export { ArticleContent, TextColumn, SharingColumn, SideColumn, FullTextColumn }
